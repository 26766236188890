var menuItems = [];
let menu=[]
let items= [
    {
        title: 'Admins',
        route: 'admins',
        codename:'view_user'
    },
    // {
    //     title: 'Env Editor',
    //     route: 'campaign-list',
    //     codename:'change_product'
    // },

    // {
    //     title: 'Notifications',
    //     route: 'gallery-add',
    //     codename:'add_product'
    // },

    // {
    //     title: 'Roles',
    //     route: 'project-list',
    //     codename:'change_product'
    // },
    {
        title: 'User Reports',
        route: 'activity-log',
        codename:'view_activitylog'
    },
    {
        title: 'Choose Viewers',
        route: 'choose-viewers',
        codename:'view_viewers'
    },
    {
        title: 'Edit By Admin',
        route: 'edit-by-admin',
        codename:'view_activitylog'
    }, 
    {
        title: 'Faqs',
        route: 'faqs',
        codename:'view_faq' 
    },
    // {
    //     title: 'Mail Templates',
    //     route: 'mail-templates',
    //     codename:'view_contact'
    // },
    {
        title: 'Contact Requests',
        route: 'contact-request',
        codename:'view_contact'
    }
]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })
})

if(menuItems.length!==0) {
    menu.push({
        title: 'Administration',
        icon: 'ShieldIcon',
        children: menuItems
    })
}
export default menu;
