// var menuItems = [];
let menu=[]
let menuItems=[]
let items= [
    {
        title: 'Heart Exchanges',
        route: 'heart-exchanges', 
        codename:'view_heartgivetake'
    },
    {
        title: 'Matches',
        route: 'matches',
        codename:'view_heartgivetake'
    },
    {
        title: 'Billing',
        route: 'billing',
        codename:'view_billing'
    },
    // {
    //     title: 'Revealed',
    //     route: 'revealed',
    //     codename:'change_product'
    // }
]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })
})

if(menuItems.length!==0) {
    menu.push({
        title: 'Heart Management',
        icon: 'HeartIcon',
        children: menuItems
    })
}
export default menu;