var menuItems = [];
let menu=[]
let items= [
    {
        title: 'Android',
        route: 'android-users',
        codename:'view_user'
    },
    {
        title: 'IOS',
        route: 'ios-users',
        codename:'view_user'
    },
    {
        title: 'Online',
        route: 'online',
        codename:'view_user'
    },
    {
        title: 'High Activity',
        route: 'high-activity',
        codename:'view_user'
    },
    {
        title: 'Low Activity',
        route: 'low-activity',
        codename:'view_user'
    },
    {
        title: 'No Activity',
        route: 'no-activity',
        codename:'view_user'
    }
]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })
})

if(menuItems.length!==0) {
    menu.push({
        title: 'User Behaviour List',
        icon: 'UserIcon',
        children: menuItems
    })
}
export default menu;
