var menuItems = [];
let menu=[]
let items= [
    {
        title: 'Users',
        route: 'users',
        codename:'view_user'
    },
    {
        title: 'Leads',
        route: 'leads',
        codename:'view_user'
    },
    {
        title: 'OTP Verified',
        route: 'otp-verified', 
        codename:'view_user'
    },
    {
        title: 'Review',
        route: 'review', 
        codename:'view_user'
    },
    {
        title: 'Edit Review',
        route: 'further-review', 
        codename:'view_user'
    },
    // {
    //     title: 'Edit ',
    //     route: 'edit', 
    //     codename:'view_user'
    // },
    {
        title: 'Further Correction Due',
        route: 'correction-due', 
        codename:'view_user'
    },
    {
        title: 'Pending Activation ',
        route: 'pending', 
        codename:'view_user'
    },
    {
        title: 'Active',
        route: 'active-profiles',
        codename:'view_user'
    },
    {
        title: 'Incompatible',
        route: 'incompatible-profiles',
        codename:'view_user'
    },
    {
        title: 'Deleted',
        route: 'deleted-profiles',
        codename:'view_user'
    },
    // {
    //     title: 'Male Users',
    //     route: 'male-users',
    //     codename:'add_product'
    // },
    // {
    //     title: 'Female Users',
    //     route: 'female-users',
    //     codename:'add_product'
    // },
    // {
    //     title: 'Premium Users',
    //     route: 'premium-users',
    //     codename:'add_product'
    // },
    // {
    //     title: 'Regular Users',
    //     route: 'regular-users',
    //     codename:'add_product'
    // },
]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })
})

if(menuItems.length!==0) {
    menu.push({
        title: 'Subscribers',
        icon: 'UsersIcon',
        children: menuItems
    })
}
export default menu;
