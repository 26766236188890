// /*
//
// Array of object
//
// Top level object can be:
// 1. Header
// 2. Group (Group can have navItems as children)
// 3. navItem
//
// * Supported Options
//
// /--- Header ---/
//
// header
//
// /--- nav Grp ---/
//
// title
// icon (if it's on top level)
// tag
// tagVariant
// children
//
// /--- nav Item ---/
//
// icon (if it's on top level)
// title
// route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
// tag
// tagVariant
//
// */
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

import dashboard from "@/navigation/vertical/biyerJuti/dashboard";
import subscribers from "@/navigation/vertical/biyerJuti/subscribers";
import heartmanagement from "@/navigation/vertical/biyerJuti/heart-management";
import administration from "@/navigation/vertical/biyerJuti/administration";
import dbmanagement from "@/navigation/vertical/biyerJuti/database-management";
import behaviour from "@/navigation/vertical/biyerJuti/user-behaviour";
import ad from "@/navigation/vertical/biyerJuti/ad";
import settings from "@/navigation/vertical/biyerJuti/settings";
// import promotion from "@/navigation/vertical/biyerJuti/promotion";

export default [
    ...dashboard,
    ...subscribers,
    ...heartmanagement,
    ...administration,
    ...settings,
    ...dbmanagement,
    ...behaviour,
    ...ad,
    // ...promotion
]

// ...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps,,]
