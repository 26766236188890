// var menu =
//     [
//         {
//             title: 'Advertisement',
//             route: 'adportal', 
//             icon: 'CodesandboxIcon',
//             codename: 'view_ad',
//         },
//     ]

// export default menu; 


let menu=[]
let menuItems=[]
let items=[
    {
        title: 'Advertisement',
        route: 'adportal', 
        codename: 'view_ad',
    },
    { 
        title: 'Promotion',
        route: 'promotion', 
        codename:'view_promotion'
    }
]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })
})

if(menuItems.length!==0) {
    menu.push({
        title: 'Ad & Promotion',
        icon: 'CodesandboxIcon',
        children: menuItems
    })
}
export default menu;


