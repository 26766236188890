var menuItems = [];
let menu= []
let items= [
    {
        title: 'Country',
        route: 'country',
        codename:'view_country'
    },
    {
        title: 'District',
        route: 'district',
        codename:'view_district'
    },
    {
        title: 'Area',
        route: 'area',
        codename:'view_area'
    },
    {
        title: 'Industry Category',
        route: 'profession',
        codename:'view_professionindustry'
    },
    {
        title: 'Designation',
        route: 'position',
        codename:'view_professiondesignation'
    },
    // {
    //     title: 'Organization',
    //     route: 'organization',
    //     codename:'view_professionorganization'
    // },
    {
        title: 'Degree',
        route: 'degree',
        codename:'view_educationdegree'
    },
    {
        title: 'Hobby',
        route: 'hobby',
        codename:'view_hobby'
    },
    // {
    //     title: 'Subject',
    //     route: 'subject',
    //     codename:'view_educationsubject'
    // },
    // {
    //     title: 'Institution',
    //     route: 'institution',
    //     codename:'view_educationinstitution'
    // },
]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })
})

if(menuItems.length!==0) {
    menu.push({
        title: 'Database Management',
        icon: 'DatabaseIcon',
        children: menuItems
    })
}

export default menu;

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })


// if(menuItems.length!==0) {
//     menu.push({
//         title: 'Database Management',
//         icon: 'DatabaseIcon',
//         children: menuItems
//     })
// }
// export default menu;