let menu=[]
let menuItems=[]
let items=[
    {
        title: 'YouTube Videos',
        route: 'videos',
        codename:'view_settings'

    },
    {
        title: 'Additional Settings',
        route: 'additional-settings',
        codename:'view_settings'
    },
    {
        title: 'Group Permission',
        route: 'group-permission',
        codename:'view_group'
    }
]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })
})

if(menuItems.length!==0) {
    menu.push({
        title: 'Settings',
        icon: 'SettingsIcon',
        children: menuItems
    })
}
export default menu;

